import { mdiCheckboxBlankCircleOutline } from '@mdi/js';

const themeConfig = {
    app: {
        logo: require('@/assets/images/logo/navbar_logo.png'),
        isDark: false,
        isRtl: false,
        contentLayoutNav: 'vertical', // vertical, horizontal
        routeTransition: 'scroll-x-transition', // https://vuetifyjs.com/en/styles/transitions/#api
        // ! `semi-dark` isn't available for horizontal nav menu
        skinVariant: 'default', // default, bordered, semi-dark
        contentWidth: 'boxed',
    },
    menu: {
        isMenuHidden: false,
        isVerticalNavMini: false,
        verticalMenuAccordion: true,
        groupChildIcon: mdiCheckboxBlankCircleOutline,
        horizontalNavMenuGroupOpenOnHover: true,
    },
    appBar: {
        /*
    ! In Content Layout Horizontal Nav type `hidden` value won't work
    ! In Content Layout Horizontal Nav type value of `type` will affect both Appbar and Horizontal Navigation Menu
    */
        type: 'fixed', // fixed, static, hidden
        isBlurred: true,
    },
    footer: {
        type: 'static', // fixed, static, hidden
    },
    themes: {
        light: {
            primary: '#0000ff',
            accent: '#0d6efd',
            secondary: '#8A8D93',
            'secondary-lighten': '#5e566914',
            success: '#56CA00',
            info: '#0000ff',
            warning: '#FFB400',
            error: '#FF4C51',
            green: '#4CAF50',
            red: '#F44336',
            orange: '#FF9800',
            amber: '#FFC107',
        },
        dark: {
            primary: '#0000ff',
            accent: '#0d6efd',
            secondary: '#8A8D93',
            success: '#56CA00',
            info: '#0000ff',
            warning: '#FFB400',
            error: '#FF4C51',
        },
    },
};

export default themeConfig;
