export default {
    Entities: 'Entities and Services',
    Facilities: 'Sanitary Facilities',
    Mobility: 'Mobility',
    Accessibility: 'Accessibility',
    Categories: 'Categories',
    Allergies: 'Allergies',
    Limitations: 'Limitations',
    Medications: 'Medications',
    Parks: 'Parks',
    Faqs: 'Faqs',
    Users: 'Users',
    User: 'User',
    Logout: 'Logout',
    OrganizationData: 'General Information',
    Search: 'Search',
    ParkList: 'Park List',
    CreatePark: 'Add Park',
    Name: 'Name',
    Email: 'E-mail',
    Country: 'Country',
    Role: 'Role',
    District: 'District',
    County: 'County',
    Parish: 'Parish',
    Managers: 'Managers',
    Lotation: 'Lotation',
    TotalLotation: 'Total Lotation',
    HandicappedLotation: 'Handicapped Lotation',
    StandingLotation: 'Standing Lotation',
    OcupationTax: 'Occupation Tax (%)',
    Filters: 'Filters',
    DeleteFilters: 'Delete Filters',
    RowsPerPage: 'Rows per page',
    ParkProfile: 'Park Profile',
    ParkStructure: 'Park Structure',
    AddPark: 'Add Park',
    Map: 'Map',
    Transports: 'Transports',
    TransportsList: 'Transports List',
    TotalCapacity: 'Total Capacity',
    HandicappedCapacity: 'Handicapped Capacity',
    StandingCapacity: 'Standing Capacity',
    FreeSeats: 'Free Seats',
    CreateTransport: 'Add Transport',
    Departure: 'Departure',
    Arrival: 'Arrival',
    Time: 'Time',
    Distance: 'Distance',
    Roadway: 'Roadway',
    Railway: 'Railway',
    Subway: 'Subway',
    TransportProfile: 'Transport Profile',
    Type: 'Type',
    TransportRoutes: 'Transport Routes',
    Routes: 'Routes',
    CreateRoute: 'Add Route',
    RouteProfile: 'Route Profile',
    TotalTime: 'Total Time',
    EmailAlreadyExists: 'This email already exists.',
    ManagerTooltip: 'Manager | Managers',
    ParkTooltip: ' Park | Parks',
    EntityTooltip: 'Service/Entity | Services/Entities',
    AllergyTooltip: 'Allergy | Allergies',
    UserTooltip: 'User | Users',
    Incapacities: 'Incapacities',
    Keywords: 'Keywords',

    //import csv
    ImportEntities: 'Import Entities',
    ImportParks: 'Import Parks',
    ImportEntityCategories: 'Import Categories',
    ImportMedications: 'Import Medications',
    ImportAllergies: 'Import Allergies',
    ImportLimitations: 'Import Limitations',
    ImportFaqs: 'Import Faqs',
    ImportLinks: 'Import Links',
    ImportRoutes: 'Import Routes',
    ImportCsv: 'Importar CSV',
    SelectFile: 'Select file',

    //entities
    EntityList: 'Entity List',
    EntityProfile: 'Entity Profile',
    Entity: 'Entity',
    CreateEntity: 'Add Entity',
    AddEntity: 'Add Entity',

    //facilities
    FacilityList: 'Sanitary Facility List',
    FacilityProfile: 'Sanitary Facility Profile',
    Facility: 'Sanitary Facility',
    CreateFacility: 'Add Sanitary Facility',
    AddFacility: 'Add  Sanitary Facility',

    //medications
    MedicationList: 'Medication List',
    MedicationProfile: 'Medication Profile',
    Medication: 'Medication',
    CreateMedication: 'Add Medication',
    AddMedication: 'Add Medication',
    Medications: 'Medications',

    //allergies
    AllergyList: 'Allergy List',
    AllergyProfile: 'Allergy Profile',
    Allergy: 'Allergy',
    CreateAllergy: 'Add Allergy',
    AddAllergy: 'Add Allergy',
    Allergies: 'Allergies',

    //limitations
    LimitationList: 'Limitation List',
    LimitationProfile: 'Limitation Profile',
    Limitation: 'Limitation',
    CreateLimitation: 'Add Limitation',
    AddLimitation: 'Add Limitation',
    Limitations: 'Limitations',

    //entities categories
    EntityCategoryList: 'Category List',
    EntityCategoryProfile: 'Category Profile',
    EntityCategory: 'Category',
    CreateEntityCategory: 'Add Category',
    AddEntityCategory: 'Add Category',

    //faqs
    FaqList: 'Faq List',
    FaqProfile: 'Faq Profile',
    Faq: 'Faq',
    CreateFaq: 'Add Faq',
    Question: 'Question',
    Answer: 'Answer',
    Category: 'Category',
    AddFaq: 'Add Faq',
    Guides: 'Guides',
    Associations: 'Associations',
    Forms: 'Forms',
    Events: 'Events',

    //links
    LinkList: 'Link List',
    LinkProfile: 'Link Profile',
    Link: 'Link',
    CreateLink: 'Add Link',
    AddLink: 'Add Link',
    Title: 'Title',
    Description: 'Description',
    Link: 'Link',
    Category: 'Category',

    //estrutura do parque
    Floors: 'Floors',
    Sections: 'Sections',
    FloorsQuantity: 'Floors Quantity',
    NumberFloors: 'Number of Floors',
    InitialFloor: 'Initial Floor Number',
    SpotsTotal: 'Total Number of Spots',
    AddFloor: 'Add Floor',
    Number: 'Number',
    Floor: 'Floor',
    Spots: 'Spots',
    SectionsNumber: 'Number of Sections',
    AddSection: 'Add Section',
    FloorsNumbers: 'Total Number of Floors:',
    SectionsNumbers: 'Total Number of Sections:',
    Limit: 'Number of spots in the section is greater than the number of spots in the floor',
    Section: 'Section',
    Identifier: 'Identifier',
    AddUser: 'Add User',
    UserProfile: 'User Profile',
    SortBy: 'Sort by',

    General: 'General',
    Individual: 'Individual',
    SpotTypes: 'Spot Types',
    Quantity: 'Quantity',
    NormalSpot: 'Normal Spots',
    PregnantSpot: 'Pregnant Spots',
    DisabledSpot: 'Disabled Spots',
    ParkContent: 'Park Content',
    Monitoring: 'Monitoring',
    SpotNumber: 'Spot Number',
    Sensor: 'Sensor',
    Actions: 'Actions',
    spotQuantityError: 'The number of lots exceeds the number of lots on the floor',
    ParkType: 'Park Type',
    ActivePark: 'Active Park',

    //alerts
    Alerts: {
        Success: 'Success!',
        UpdateSuccessEnt: 'Information updated successfully!',
        Error: 'Error!',
        ErrorMessage: 'An error occurred. Please try again later.',
        ErrorLogin: 'An error occurred. Incorrect email or password.',
        DeleteSuccess: 'Park deleted successfully.',
        UpdateSuccess: 'Park updated successfully.',
        AddSuccess: 'Park added successfully.',
        Sure: 'Are you sure?',
        SureDelete: 'Are you sure you want to delete the park',
        SureDeleteParks: 'Are you sure you want to delete the parks',
        DeleteUser: 'Are you sure you want to delete the user',
        SureDeleteUsers: 'Are you sure you want to delete the users',
        Irreversible: 'This action is irreversible.',
        DeleteSuccessUser: 'User deleted successfully.',
        AddUserSuccess: 'User added successfully.',
        UpdateUserSuccess: 'User updated successfully.',
        DeleteUserSuccess: 'User deleted successfully.',
        AddTransportSuccess: 'Transport added successfully.',
        UpdateTransportSuccess: 'Transport updated successfully.',
        DeleteTransportSuccess: 'Transport deleted successfully.',
        DeleteTransport: 'Are you sure you want to delete the transport',
        SureDeleteTransports: 'Are you sure you want to delete the transports',
        SureDeleteFloor: 'Are you sure you want to delete the floor',
        SureDeleteSpot: 'Are you sure you want to delete the lot',
        SureDeleteSection: 'Are you sure you want to delete the section',
        SureDeleteGeneralLot: 'Are you sure you want to delete the lot',
        AddEntitySuccess: 'Entity added successfully.',
        UpdateEntitySuccess: 'Entity updated successfully.',
        DeleteEntitySuccess: 'Entity deleted successfully.',
        SureDeleteEntity: 'Are you sure you want to delete the entity',
        SureDeleteEntities: 'Are you sure you want to delete the entities',
        AddFacilitySuccess: 'Sanitary Facility added successfully.',
        UpdateFacilitySuccess: 'Sanitary Facility updated successfully.',
        DeleteFacilitySuccess: 'Sanitary Facility deleted successfully.',
        SureDeleteFacility: 'Are you sure you want to delete the sanitary facility',
        SureDeleteFacilities: 'Are you sure you want to delete the sanitary facilities',
        AddEntityCategorySuccess: 'Category added successfully.',
        UpdateEntityCategorySuccess: 'Category updated successfully.',
        DeleteEntityCategorySuccess: 'Category deleted successfully.',
        SureDeleteEntityCategory: 'Are you sure you want to delete the category',
        SureDeleteEntityCategories: 'Are you sure you want to delete the categories',
        AddMedicationSuccess: 'Medication added successfully.',
        UpdateMedicationSuccess: 'Medication updated successfully.',
        DeleteMedicationSuccess: 'Medication deleted successfully.',
        SureDeleteMedication: 'Are you sure you want to delete the medication',
        SureDeleteMedications: 'Are you sure you want to delete the medications',
        AddAllergySuccess: 'Allergy added successfully.',
        UpdateAllergySuccess: 'Allergy updated successfully.',
        DeleteAllergySuccess: 'Allergy deleted successfully.',
        SureDeleteAllergy: 'Are you sure you want to delete the allergy',
        SureDeleteAllergies: 'Are you sure you want to delete the allergies',
        AddLimitationSuccess: 'Limitation added successfully.',
        UpdateLimitationSuccess: 'Limitation updated successfully.',
        DeleteLimitationSuccess: 'Limitation deleted successfully.',
        SureDeleteLimitation: 'Are you sure you want to delete the limitation',
        SureDeleteLimitations: 'Are you sure you want to delete the limitations',
        AddFaqSuccess: 'FAQ added successfully.',
        UpdateFaqSuccess: 'FAQ updated successfully.',
        DeleteFaqSuccess: 'FAQ deleted successfully.',
        SureDeleteFaq: 'Are you sure you want to delete the FAQ',
        SureDeleteFaqs: 'Are you sure you want to delete the FAQs',
        AddLinkSuccess: 'Link added successfully.',
        UpdateLinkSuccess: 'Link updated successfully.',
        DeleteLinkSuccess: 'Link deleted successfully.',
        SureDeleteLink: 'Are you sure you want to delete the link',
        SureDeleteLinks: 'Are you sure you want to delete the links',
        ImportEntitiesSuccess: 'Entities imported successfully.',
        ImportParksSuccess: 'Parks imported successfully.',
        ImportEntityCategoriesSuccess: 'Categories imported successfully.',
        ImportMedicationsSuccess: 'Medications imported successfully.',
        ImportAllergiesSuccess: 'Allergies imported successfully.',
        ImportLimitationsSuccess: 'Limitations imported successfully.',
        ImportFaqsSuccess: 'Faqs imported successfully.',
        ImportLinksSuccess: 'Links imported successfully.',
        ImportRoutesSuccess: 'Routes imported successfully.',
    },

    //buttons
    Buttons: {
        Save: 'Save',
        Conclude: 'Conclude',
        Cancel: 'Cancel',
        Edit: 'Edit',
        Delete: 'Delete',
        Filter: 'Filter',
        Add: 'Add',
        Import: 'Import',
    },

    Form: {
        Name: 'Name',
        Email: 'E-mail',
        Nif: 'VAT Number',
        Phone: 'Phone',
        Schedule: 'Schedule',
        Schedules: 'Schedules',
        Cost: 'Cost',
        ParkManagers: 'Park Managers',
        Sensors: 'Sensors',
        Address: 'Address',
        PostalCode: 'Postal Code',
        Length: 'Length',
        Width: 'Width',
        LatLng: 'Latitude, Longitude',
        Gender: 'Gender',
        TransportManagers: 'Transport Managers',
        Entity: 'Service/Entity',
        Keywords: 'Keywordsn (eg. keyword1, keyword2...)',
    },
    isWheelchairAccessible: 'Wheelchair Accessible',
    isBlindAccessible: 'Blind Accessible',
    isDeafAccessible: 'Deaf Accessible',
    disabilityType: 'Disability',
    Language: {
        English: 'English',
        Portuguese: 'Portuguese',
    },
    Login: {
        Login: 'Login',
        Welcome: 'Welcome to Altice',
        Password: 'Password',
        Forgot: 'Forgot your password?',
    },
};
