import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: 'login',
    },

    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/login-demo',
        name: 'LoginDemo',
        component: () => import('@/views/LoginDemo.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/entity-list',
        name: 'EntityList',
        component: () => import('@/views/entities/Entity.vue'),
        meta: {
            layout: 'content',
        },
        children: [
            {
                path: '',
                name: 'EntityList',
                component: () => import('@/views/entities/entity-list/EntityList.vue'),
            },
            {
                path: 'entity-profile/:id',
                name: 'EntityProfile',
                component: () => import('@/views/entities/entity-profile/EntityForm.vue'),
            },
            {
                path: 'import-entities',
                name: 'importEntities',
                component: () => import('@/views/entities/ImportEntities.vue'),
            },
        ],
    },
    {
        path: '/facility-list',
        name: 'FacilityList',
        component: () => import('@/views/facilities/Facility.vue'),
        meta: {
            layout: 'content',
        },
        children: [
            {
                path: '',
                name: 'FacilityList',
                component: () => import('@/views/facilities/facility-list/FacilityList.vue'),
            },
            {
                path: 'facility-profile/:id',
                name: 'FacilityProfile',
                component: () => import('@/views/facilities/facility-profile/FacilityForm.vue'),
            },
            {
                path: 'import-facilities',
                name: 'importFacilities',
                component: () => import('@/views/facilities/ImportFacilities.vue'),
            },
        ],
    },
    {
        path: '/medication-list',
        name: 'MedicationList',
        component: () => import('@/views/medications/Medication.vue'),
        meta: {
            layout: 'content',
        },
        children: [
            {
                path: '',
                name: 'MedicationList',
                component: () => import('@/views/medications/medication-list/MedicationList.vue'),
            },
            {
                path: 'medication-profile/:id',
                name: 'MedicationProfile',
                component: () => import('@/views/medications/medication-profile/MedicationForm.vue'),
            },
            {
                path: 'import-medications',
                name: 'importMedications',
                component: () => import('@/views/medications/ImportMedications.vue'),
            },
        ],
    },
    {
        path: '/entity-category-list',
        name: 'EntityCategoryList',
        component: () => import('@/views/entities-categories/EntityCategory.vue'),
        meta: {
            layout: 'content',
        },
        children: [
            {
                path: '',
                name: 'EntityCategoryList',
                component: () => import('@/views/entities-categories/entity-category-list/EntityCategoryList.vue'),
            },
            {
                path: 'entity-category-profile/:id',
                name: 'EntityCategoryProfile',
                component: () => import('@/views/entities-categories/entity-category-profile/EntityCategoryForm.vue'),
            },
            {
                path: 'import-entity-categories',
                name: 'importEntityCategories',
                component: () => import('@/views/entities-categories/ImportEntityCategories.vue'),
            },
        ],
    },
    {
        path: '/allergy-list',
        name: 'AllergyList',
        component: () => import('@/views/allergies/Allergy.vue'),
        meta: {
            layout: 'content',
        },
        children: [
            {
                path: '',
                name: 'AllergyList',
                component: () => import('@/views/allergies/allergy-list/AllergyList.vue'),
            },
            {
                path: 'allergy-profile/:id',
                name: 'AllergyProfile',
                component: () => import('@/views/allergies/allergy-profile/AllergyForm.vue'),
            },
            {
                path: 'import-allergies',
                name: 'importAllergies',
                component: () => import('@/views/allergies/ImportAllergies.vue'),
            },
        ],
    },
    {
        path: '/limitation-list',
        name: 'LimitationList',
        component: () => import('@/views/limitations/Limitation.vue'),
        meta: {
            layout: 'content',
        },
        children: [
            {
                path: '',
                name: 'LimitationList',
                component: () => import('@/views/limitations/limitation-list/LimitationList.vue'),
            },
            {
                path: 'limitation-profile/:id',
                name: 'LimitationProfile',
                component: () => import('@/views/limitations/limitation-profile/LimitationForm.vue'),
            },
            {
                path: 'import-limitations',
                name: 'importLimitations',
                component: () => import('@/views/limitations/ImportLimitations.vue'),
            },
        ],
    },
    {
        path: '/park-list',
        name: 'ParkList',
        component: () => import('@/views/parks/Park.vue'),
        meta: {
            layout: 'content',
        },
        children: [
            {
                path: '',
                name: 'ParkList',
                component: () => import('@/views/parks/park-list/ParkList.vue'),
            },
            {
                path: 'park-profile/:id',
                name: 'ParkProfile',
                component: () => import('@/views/parks/park-profile/ParkForm.vue'),
            },
            {
                path: 'import-parks',
                name: 'importParks',
                component: () => import('@/views/parks/ImportParks.vue'),
            },
        ],
    },
    {
        path: '/faq-list',
        name: 'FaqList',
        component: () => import('@/views/faqs/Faq.vue'),
        meta: {
            layout: 'content',
        },
        children: [
            {
                path: '',
                name: 'FaqList',
                component: () => import('@/views/faqs/faq-list/FaqList.vue'),
            },
            {
                path: 'faq-profile/:id',
                name: 'FaqProfile',
                component: () => import('@/views/faqs/faq-profile/FaqForm.vue'),
            },
            {
                path: 'import-faqs',
                name: 'importFaqs',
                component: () => import('@/views/faqs/ImportFaqs.vue'),
            },
        ],
    },
    {
        path: '/link-list',
        name: 'LinkList',
        component: () => import('@/views/links/Link.vue'),
        meta: {
            layout: 'content',
        },
        children: [
            {
                path: '',
                name: 'LinkList',
                component: () => import('@/views/links/link-list/LinkList.vue'),
            },
            {
                path: 'link-profile/:id',
                name: 'LinkProfile',
                component: () => import('@/views/links/link-profile/LinkForm.vue'),
            },
            {
                path: 'import-links',
                name: 'importLinks',
                component: () => import('@/views/links/ImportLinks.vue'),
            },
        ],
    },
    {
        path: '/user-list',
        name: 'UserList',
        component: () => import('@/views/user/User.vue'),
        meta: {
            layout: 'content',
        },
        children: [
            {
                path: '',
                name: 'UserList',
                component: () => import('@/views/user/user-list/UserList.vue'),
            },
            {
                path: 'user-profile/:id',
                name: 'UserProfile',
                component: () => import('@/views/user/user-profile/UserForm.vue'),
            },
        ],
    },

    {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/Login.vue'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '/routes-list',
        name: 'RoutesList',
        component: () => import('@/views/route/Route.vue'),
        meta: {
            layout: 'content',
        },
        children: [
            {
                path: '',
                name: 'RoutesList',
                component: () => import('@/views/route/MainTabs.vue'),
            },
            {
                path: 'route-profile/:id',
                name: 'RouteProfile',
                component: () => import('@/views/route/routes-profile/RoutesForm.vue'),
            },
            {
                path: 'import-routes',
                name: 'importRoutes',
                component: () => import('@/views/route/ImportRoutes.vue'),
            },
            {
                path: 'trip-profile/:id',
                name: 'TripProfile',
                component: () => import('@/views/route/trips-profile/TripsForm.vue'),
            },
            {
                path: 'stop-times-profile/:id',
                name: 'StopTimesProfile',
                component: () => import('@/views/route/stop-times-profile/StopTimesForm.vue'),
            },
            {
                path: 'calendar-profile/:id',
                name: 'CalendarProfile',
                component: () => import('@/views/route/calendar/calendar-profile/CalendarForm.vue'),
            },
            {
                path: 'calendar-dates-profile/:id',
                name: 'CalendarDatesProfile',
                component: () => import('@/views/route/calendar/calendar-dates-profile/CalendarDatesForm.vue'),
            },
        ],
    },
    {
        path: '*',
        redirect: 'error-404',
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

export default router;
