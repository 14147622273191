export default {
    Entities: 'Serviços e Entidades',
    Facilities: 'Instalações Sanitárias',
    Mobility: 'Mobilidade',
    Accessibility: 'Acessibilidade',
    Categories: 'Categorias',
    Allergies: 'Alergias',
    Limitations: 'Limitações',
    Medications: 'Medicamentos',
    Parks: 'Parques',
    Faqs: 'FAQs',
    Users: 'Utilizadores',
    User: 'Utilizador',
    Logout: 'Terminar Sessão',
    OrganizationData: 'Informação geral',
    Search: 'Pesquisar',
    ParkList: 'Lista de Parques',
    CreatePark: 'Criar Parque',
    Name: 'Nome',
    Email: 'E-mail',
    Country: 'País',
    Role: 'Cargo',
    District: 'Distrito',
    County: 'Concelho',
    Parish: 'Freguesia',
    Managers: 'Gerentes',
    Lotation: 'Lotação',
    TotalLotation: 'Lotação Total',
    HandicappedLotation: 'Lotação para Incapacitados',
    StandingLotation: 'Lotação em Pé',
    OcupationTax: 'Taxa de Ocupação (%)',
    Filters: 'Filtros',
    DeleteFilters: 'Apagar Filtros',
    RowsPerPage: 'Linhas por página',
    ParkProfile: 'Perfil do Parque',
    ParkStructure: 'Estrutura do Parque',
    AddPark: 'Adicionar Parque',
    Map: 'Mapa',
    Transports: 'Veículos',
    TransportsList: 'Lista de Veículos',
    CreateTransport: 'Criar Veículo',
    Departure: 'Partida',
    Arrival: 'Chegada',
    Time: 'Tempo',
    Distance: 'Distância',
    Roadway: 'Autocarro',
    Railway: 'Comboio',
    Subway: 'Metro',
    AddTransport: 'Adicionar Veículo',
    TransportProfile: 'Perfil do Veículo',
    Type: 'Tipo',
    TransportRoutes: 'Rotas que efetua',
    Routes: 'Rotas',
    CreateRoute: 'Criar Rota',
    RouteProfile: 'Perfil da Rota',
    TotalTime: 'Tempo Total',
    EmailAlreadyExists: 'Este email já existe.',
    ManagerTooltip: 'Gerente | Gerentes',
    ParkTooltip: 'Parque | Parques',
    EntityTooltip: 'Serviço/Entidade | Serviços/Entidades',
    AllergyTooltip: 'Alergia | Alergias',
    UserTooltip: 'Utilizador | Utilizadores',
    Continue: 'Continuar',
    CreateTrip: 'Criar Viagem',
    CreateCalendar: 'Criar Calendário',
    TotalCapacity: 'Capacidade Total',
    HandicappedCapacity: 'Capacidade Incapacitados',
    StandingCapacity: 'Capacidade em Pé',
    FreeSeats: 'Lugares Livres',
    CreateStopTimes: 'Criar Horários',
    Schedule: 'Horário',
    Schedules: 'Horários',
    ScheduleID: 'ID do Horário',
    Transport: 'Veículo',
    Price: 'Preço',
    SortOrder: 'Ordem da rota',
    Incapacities: 'Incapacidades',
    Keywords: 'Palavras-chave',

    //import csv
    ImportParks: 'Importar Parques',
    ImportEntities: 'Importar Entidades',
    ImportEntityCategories: 'Importar Categorias',
    ImportFacilities: 'Importar Instalações Sanitárias',
    ImportMedications: 'Importar Medicamentos',
    ImportAllergies: 'Importar Alergias',
    ImportLimitations: 'Importar Limitações',
    ImportFaqs: 'Importar FAQs',
    ImportLinks: 'Importar Links',
    ImportRoutes: 'Importar Rotas',
    ImportCsv: 'Importar CSV',
    SelectFile: 'Selecionar ficheiro',

    //entities
    EntityList: 'Lista de Entidades',
    EntityProfile: 'Perfil da Entidade',
    Entity: 'Entidade',
    CreateEntity: 'Criar Entidade',
    AddEntity: 'Adicionar Entidade',

    //facilities
    FacilityList: 'Lista de Instalações Sanitárias',
    FacilityProfile: 'Perfil da Instalação Sanitária',
    Facility: 'Instalação Sanitária',
    CreateFacility: 'Criar Instalação Sanitária',
    AddFacility: 'Adicionar Instalação Sanitária',

    //entities categories
    EntityCategoryList: 'Lista de Categorias',
    EntityCategoryProfile: 'Perfil da Categoria',
    EntityCategory: 'Categoria',
    CreateEntityCategory: 'Criar Categoria',
    AddEntityCategory: 'Adicionar Categoria',

    //medications
    MedicationList: 'Lista de Medicamentos',
    MedicationProfile: 'Perfil do Medicamento',
    Medication: 'Medicamento',
    CreateMedication: 'Criar Medicamento',
    AddMedication: 'Adicionar Medicamento',
    Medications: 'Medicamentos',

    //allergies
    AllergyList: 'Lista de Alergias',
    AllergyProfile: 'Perfil da Alergia',
    Allergy: 'Alergia',
    CreateAllergy: 'Criar Alergia',
    AddAllergy: 'Adicionar Alergia',
    Allergies: 'Alergias',

    //limitations
    LimitationList: 'Lista de Limitações',
    LimitationProfile: 'Perfil da Limitação',
    Limitation: 'Limitação',
    CreateLimitation: 'Criar Limitação',
    AddLimitation: 'Adicionar Limitação',
    Limitations: 'Limitações',

    //faqs
    FaqList: 'Lista de FAQs',
    FaqProfile: 'Perfil da FAQ',
    Faq: 'FAQ',
    CreateFaq: 'Criar FAQ',
    Question: 'Questão',
    Answer: 'Resposta',
    Category: 'Categoria',
    AddFaq: 'Adicionar FAQ',

    //links
    LinkList: 'Lista de Links',
    LinkProfile: 'Perfil do Link',
    Link: 'Link',
    CreateLink: 'Criar Link',
    AddLink: 'Adicionar Link',
    Title: 'Título',
    Guides: 'Guias',
    Associations: 'Associações',
    Forms: 'Formulários',
    Events: 'Eventos',

    //estrutura do parque
    Floors: 'Andar|Andares',
    Sections: 'Secção | Secções',
    FloorsQuantity: 'Quantidade de Andares',
    NumberFloors: 'Nº de Andares',
    InitialFloor: 'Nº do andar inicial',
    SpotsTotal: 'Número total de lugares',
    AddFloor: 'Adicionar Andar',
    Number: 'Nº',
    Floor: 'Andar',
    Spots: 'Lugar | Lugares',
    SectionsNumber: 'Nº de Secções',
    AddSection: 'Adicionar Secção',
    FloorsNumbers: 'Número total de andares:',
    SectionsNumbers: 'Número total de secções:',
    Limit: 'Número de lugares da secção é maior do que o número de lugares do andar',
    Section: 'Secção',
    Identifier: 'Identificação',
    AddUser: 'Adicionar Utilizador',
    UserProfile: 'Perfil do Utilizador',
    SortBy: 'Ordenar por',
    General: 'Geral',
    Individual: 'Individual',
    SpotTypes: 'Tipo de lugares',
    Quantity: 'Quantidade',
    NormalSpot: 'Lugares normais',
    PregnantSpot: 'Lugares grávidas',
    DisabledSpot: 'Lugares deficientes',
    ParkContent: 'Conteúdo do parque',
    Monitoring: 'Monitorização',
    SpotNumber: 'Número do lugar',
    Sensor: 'Sensor',
    Actions: 'Ações',
    spotQuantityError: 'A quantidade de lugares ultrapassa o número de lugares do andar',
    ParkType: 'Tipo de parque',
    ActivePark: 'Parque ativo',

    //estrutura das rotas
    Agency: 'Agência',
    Description: 'Descrição',
    Route: 'Rota',
    Trips: 'Viagens',
    Trip: 'Viagem',
    Calendar: 'Calendário',
    ShortName: 'Nome curto',
    LongName: 'Nome longo',
    Color: 'Cor',
    TextColor: 'Cor do texto',
    TrajecTorder: 'Ordem de trajeto',
    TripsList: 'Lista de viagens',
    SchedulesList: 'Lista de horários',
    BikesAllowed: 'Bicicletas permitidas',
    WheelchairAcessible: 'Acessível a cadeiras de rodas',
    Headsign: 'Cabeçalho',
    PickupType: 'Tipo de embarque',
    DropOffType: 'Tipo de deembarque',
    CalendarException: 'Exceção de calendário',
    Monday: 'Segunda-feira | Segundas-feiras',
    Tuesday: 'Terça-feira | Terças-feiras',
    Wednesday: 'Quarta-feira | Quartas-feiras',
    Thursday: 'Quinta-feira | Quintas-feiras',
    Friday: 'Sexta-feira | Sextas-feiras',
    Saturday: 'Sábado | Sábados',
    Sunday: 'Domingo | Domingos',
    StartDate: 'Data de início',
    EndDate: 'Data de fim',
    ExceptionType: 'Tipo de exceção',
    Date: 'Data',
    AddRoute: 'Adicionar Rota',
    RouteProfile: 'Perfil da Rota',
    NoInfoBikes: 'Sem informação para esta viagem',
    BikesAllowedInfo: 'O veículo usado nesta viagem em particular pode acomodar pelo menos uma bicicleta',
    BikesNotAllowedInfo: 'Não são permitidas bicicletas nesta viagem',
    NoInfoWheelchair: 'Sem informação para esta viagem',
    WheelchairAllowedInfo:
        'O veículo usado nesta viagem em particular pode acomodar pelo menos um passageiro em cadeira de rodas',
    WheelchairNotAllowedInfo: 'Nenhum passageiro em cadeira de rodas pode ser acomodado nesta viagem',
    FareRules: 'Regras tarifárias',

    //tipos de rotas
    Tram: 'Elétrico',
    Subway: 'Metro',
    Rail: 'Comboio',
    Bus: 'Autocarro',
    Ferry: 'Ferry',
    CableTram: 'Elétrico de cabo',
    AerialLift: 'Elevador aéreo',
    Funicular: 'Funicular',
    Trolleybus: 'Autocarro elétrico',
    Monorail: 'Monocarril',

    //tipos de disponibilidade no calendário

    calendarAvailable: 'Disponível todos(as) os(as)',
    calendarNotAvailable: 'Não disponível todos(as) os(as)',

    calendarDatesAvailable: 'Serviço disponível nesta data',
    calendarDatesNotAvailable: 'Serviço indisponível nesta data',

    //tipos de pickup e dropoff
    ContinuosPickup: 'Pickup de parada contínuo',
    NoContinuosPickup: 'Sem pickup de parada contínua',
    PhoneAgencyPickup: 'Deve telefonar para a agência para fazer o pickup',
    PhoneDriverPickup: 'Deve coordenar com um motorista para fazer o pickup',
    RegularPickup: 'Pickup regular',
    NoPickupAvailable: 'Não há pickup disponível',

    ContinuosDropOff: 'Desembarque de parada contínuo',
    NoContinuosDropOff: 'Sem desembarque de parada contínua',
    PhoneAgencyDropOff: 'Deve telefonar para a agência para fazer o desembarque',
    PhoneDriverDropOff: 'Deve coordenar com um motorista para fazer o desembarque',
    RegularDropOff: 'Desembarque regular',
    NoDropOffAvailable: 'Não há desembarque disponível',

    //trip form
    Direction: 'Direção',
    AddTrip: 'Adicionar Viagem',
    TripProfile: 'Perfil da Viagem',
    DirectionOutbound: 'Viaja numa direção (por exemplo, viagem de ida)',
    DirectionInbound: 'Viaja na direção oposta (por exemplo, viagem de volta)',
    ApproximateTime: 'Tempo aproximado',
    ExactTime: 'Tempo exato',

    //stop times form
    Sequence: 'Sequência',
    ShapeDistance: 'Distância até à próxima paragem',
    Timepoint: 'Tempo de paragem',
    ArrivalTime: 'Hora de chegada',
    DepartureTime: 'Hora de partida',
    AddStopTime: 'Adicionar horário',
    StopTimeProfile: 'Perfil do horário',
    Stop: 'Paragem',

    //calendar form
    CalendarProfile: 'Perfil do Calendário',
    AddCalendar: 'Adicionar Calendário',
    AlwaysAvailable: 'Sempre disponível',
    NotAlwaysAvailable: 'Não está sempre disponível',
    DaysAlwaysAvailable: 'Dias em que está sempre disponível',
    SelectAll: 'Selecionar todos',
    DeselectAll: 'Remover selecionados',

    //calendar dates form
    CreateCalendarDate: 'Criar exceção ao calendário',
    AddCalendarDate: 'Adicionar exceção ao calendário',
    CalendarDateProfile: 'Perfil da exceção ao calendário',

    //alerts
    Alerts: {
        Success: 'Sucesso!',
        UpdateSuccessEnt: 'Informação atualizada com sucesso!',
        Error: 'Erro!',
        ErrorMessage: 'Ocorreu um erro. Tente novamente mais tarde.',
        ErrorLogin: 'Ocorreu um erro. Verifique o seu email e palavra-passe.',
        DeleteSuccess: 'Parque eliminado com sucesso.',
        UpdateSuccess: 'Parque atualizado com sucesso.',
        AddSuccess: 'Parque adicionado com sucesso.',
        Sure: 'Tem a certeza?',
        SureDelete: 'Tem a certeza que pretende eliminar o parque',
        SureDeleteParks: 'Tem a certeza que pretende eliminar os parques',
        DeleteUser: 'Tem a certeza que pretende eliminar o utilizador',
        SureDeleteUsers: 'Tem a certeza que pretende eliminar os ',
        Irreversible: 'Esta ação é irreversível.',
        DeleteSuccessUser: 'Utilizador eliminado com sucesso.',
        AddUserSuccess: 'Utilizador adicionado com sucesso.',
        UpdateUserSuccess: 'Utilizador atualizado com sucesso.',
        DeleteUserSuccess: 'Utilizador eliminado com sucesso.',
        AddTransportSuccess: 'Veículo adicionado com sucesso.',
        UpdateTransportSuccess: 'Veículo atualizado com sucesso.',
        DeleteTransportSuccess: 'Veículo eliminado com sucesso.',
        DeleteTransport: 'Tem a certeza que pretende eliminar o veículo',
        SureDeleteTransports: 'Tem a certeza que pretende eliminar os veículos',
        SureDeleteFloor: 'Tem a certeza que pretende eliminar o andar',
        SureDeleteSpot: 'Tem a certeza que pretende eliminar o lugar',
        SureDeleteSection: 'Tem a certeza que pretende eliminar a secção',
        SureDeleteGeneralLot: 'Tem a certeza que pretende eliminar o lote',
        AddEntitySuccess: 'Entidade adicionada com sucesso.',
        UpdateEntitySuccess: 'Entidade atualizada com sucesso.',
        DeleteEntitySuccess: 'Entidade eliminada com sucesso.',
        SureDeleteEntity: 'Tem a certeza que pretende eliminar a entidade',
        SureDeleteEntities: 'Tem a certeza que pretende eliminar as entidades',
        AddFacilitySuccess: 'Instalação sanitária adicionada com sucesso.',
        UpdateFacilitySuccess: 'Instalação sanitária atualizada com sucesso.',
        DeleteFacilitySuccess: 'Instalação sanitária eliminada com sucesso.',
        SureDeleteFacility: 'Tem a certeza que pretende eliminar a instalação sanitária',
        SureDeleteFacilities: 'Tem a certeza que pretende eliminar as instalações sanitárias',
        AddEntityCategorySuccess: 'Categoria adicionada com sucesso.',
        UpdateEntityCategorySuccess: 'Categoria atualizada com sucesso.',
        DeleteEntityCategorySuccess: 'Categoria eliminada com sucesso.',
        SureDeleteEntityCategory: 'Tem a certeza que pretende eliminar a categoria',
        SureDeleteEntityCategories: 'Tem a certeza que pretende eliminar as categorias',
        AddMedicationSuccess: 'Medicamento adicionado com sucesso.',
        UpdateMedicationSuccess: 'Medicamento atualizado com sucesso.',
        DeleteMedicationSuccess: 'Medicamento eliminado com sucesso.',
        SureDeleteMedication: 'Tem a certeza que pretende eliminar o medicamento',
        SureDeleteMedications: 'Tem a certeza que pretende eliminar os medicamentos',
        AddAllergySuccess: 'Alergia adicionada com sucesso.',
        UpdateAllergySuccess: 'Alergia atualizada com sucesso.',
        DeleteAllergySuccess: 'Alergia eliminada com sucesso.',
        SureDeleteAllergy: 'Tem a certeza que pretende eliminar a alergia',
        SureDeleteAllergies: 'Tem a certeza que pretende eliminar as alergias',
        AddLimitationSuccess: 'Limitação adicionada com sucesso.',
        UpdateLimitationSuccess: 'Limitação atualizada com sucesso.',
        DeleteLimitationSuccess: 'Limitação eliminada com sucesso.',
        SureDeleteLimitation: 'Tem a certeza que pretende eliminar a limitação',
        SureDeleteLimitations: 'Tem a certeza que pretende eliminar as limitações',
        AddFaqSuccess: 'FAQ adicionada com sucesso.',
        UpdateFaqSuccess: 'FAQ atualizada com sucesso.',
        DeleteFaqSuccess: 'FAQ eliminada com sucesso.',
        SureDeleteFaq: 'Tem a certeza que pretende eliminar a FAQ',
        SureDeleteFaqs: 'Tem a certeza que pretende eliminar as FAQs',
        AddLinkSuccess: 'Link adicionado com sucesso.',
        UpdateLinkSuccess: 'Link atualizado com sucesso.',
        DeleteLinkSuccess: 'Link eliminado com sucesso.',
        SureDeleteLink: 'Tem a certeza que pretende eliminar o link',
        SureDeleteLinks: 'Tem a certeza que pretende eliminar os links',
        AddRouteSueccess: 'Rota adicionada com sucesso.',
        UpdateRouteSueccess: 'Rota atualizada com sucesso.',
        DeleteRouteSueccess: 'Rota eliminada com sucesso.',
        SureDeleteRoute: 'Tem a certeza que pretende eliminar a rota',
        AddTripSueccess: 'Viagem adicionada com sucesso.',
        UpdateTripSueccess: 'Viagem atualizada com sucesso.',
        DeleteTripSueccess: 'Viagem eliminada com sucesso.',
        SureDeleteTrip: 'Tem a certeza que pretende eliminar a viagem',
        DeleteRouteSueccess: 'Viagem eliminada com sucesso.',
        AddStopTimeSuccess: 'Horário adicionado com sucesso.',
        UpdateStopTimeSuccess: 'Horário atualizado com sucesso.',
        DeleteStopTimeSuccess: 'Horário eliminado com sucesso.',
        SureDeleteStopTime: 'Tem a certeza que pretende eliminar o horário',
        AddCalendarSuccess: 'Calendário adicionado com sucesso.',
        UpdateCalendarSuccess: 'Calendário atualizado com sucesso.',
        DeleteCalendarSuccess: 'Calendário eliminado com sucesso.',
        SureDeleteCalendar: 'Tem a certeza que pretende eliminar o calendário',
        AddCalendarDateSuccess: 'Exceção ao calendário adicionada com sucesso.',
        UpdateCalendarDateSuccess: 'Exceção ao calendário atualizada com sucesso.',
        DeleteCalendarDateSuccess: 'Exceção ao calendário eliminada com sucesso.',
        SureDeleteCalendarDate: 'Tem a certeza que pretende eliminar a exceção ao calendário',
        ImportEntitiesSuccess: 'Entidades importadas com sucesso.',
        ImportParksSuccess: 'Parques importados com sucesso.',
        ImportEntityCategoriesSuccess: 'Categorias importadas com sucesso.',
        ImportFacilitiesSuccess: 'Instalações sanitárias importadas com sucesso.',
        ImportMedicationsSuccess: 'Medicamentos importados com sucesso.',
        ImportAllergiesSuccess: 'Alergias importadas com sucesso.',
        ImportLimitationsSuccess: 'Limitações importadas com sucesso.',
        ImportFaqsSuccess: 'FAQs importadas com sucesso.',
        ImportLinksSuccess: 'Links importados com sucesso.',
        ImportRoutesSuccess: 'Rotas importadas com sucesso.',
    },
    //buttons
    Buttons: {
        Save: 'Guardar',
        Conclude: 'Concluir',
        Cancel: 'Cancelar',
        Edit: 'Editar',
        Delete: 'Apagar',
        Filter: 'Filtrar',
        Add: 'Adicionar',
        Import: 'Importar',
    },

    Form: {
        Name: 'Nome',
        Email: 'E-mail',
        Nif: 'NIF',
        Phone: 'Telemóvel',
        Schedule: 'Horário',
        Cost: 'Custo',
        ParkManagers: 'Gestores do Parque',
        Sensors: 'Sensores',
        Address: 'Morada',
        PostalCode: 'Código Postal',
        LatLng: 'Latitude, Longitude',
        Length: 'Comprimento',
        Width: 'Largura',
        Gender: 'Género',
        TransportManagers: 'Gestores do Veículo',
        Entity: 'Serviço/Entidade',
        Keywords: 'Palavras-chave (ex: palavra-chave1, palavra-chave2...)',
    },

    IsWheelchairAccessible: 'Acessível a cadeiras de rodas',
    IsBlindAccessible: 'Acessível a invisuais',
    IsDeafAccessible: 'Acessível a surdos',
    DisabilityType: 'Incapacidade',

    Language: {
        English: 'Inglês',
        Portuguese: 'Português',
    },
    Login: {
        Login: 'Login',
        Welcome: 'Bem-vindo à Altice',
        Password: 'Palavra-passe',
        Forgot: 'Esqueceu-se da palavra-passe?',
    },
};
