<template>
    <div class="bottom-items">
        <div>
            <v-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text">
                <v-img
                    :src="
                        $store.getters['auth/getUser'].picture
                            ? $store.getters['auth/getUser'].picture.formats.small.url
                            : require('@/assets/images/logo/profile.png')
                    "></v-img
            ></v-avatar>
            <span
                v-if="!(menuIsVerticalNavMini && $vuetify.breakpoint.lgAndUp && !isMouseHovered)"
                class="text--primary font-weight-semibold mb-n1 ml-5">
                {{ name }}
            </span>
        </div>
        <div @click="logOut" class="py-4">
            <v-btn icon>
                <v-icon large>{{ icons.mdiPower }}</v-icon>
            </v-btn>
            <span
                v-if="!(menuIsVerticalNavMini && $vuetify.breakpoint.lgAndUp && !isMouseHovered)"
                class="text--primary font-weight-light mb-n1 ml-5">
                {{ $t('Logout') }}
            </span>
        </div>
    </div>
</template>

<script>
    import useAppConfig from '@core/@app-config/useAppConfig';
    import { mdiPower } from '@mdi/js';
    import themeConfig from '@themeConfig';
    import { inject } from '@vue/composition-api';

    export default {
        data() {
            return {
                name: this.$store.getters['auth/getUser'].name,
                isLoading: false,
            };
        },
        methods: {
            logOut() {
                this.isLoading = true;
                this.$store.commit('auth/resetUser');
                console.log(this.$store.getters['auth/getUser']);
                this.$router.push({ name: 'Login' });
            },
        },
        setup() {
            const { menuIsVerticalNavMini } = useAppConfig();
            const isMouseHovered = inject('isMouseHovered');
            return {
                menuIsVerticalNavMini,
                isMouseHovered,
                appName: themeConfig.app.name,
                appLogo: themeConfig.app.logo,

                // Icons
                icons: {
                    mdiPower,
                },
            };
        },
    };
</script>

<style>
    .bottom-items {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateX(15px);
    }
</style>
