import { i18n } from '@/plugins/i18n';
import {
    mdiAccountOutline,
    mdiHomeOutline,
    mdiHumanMaleFemale,
    mdiNoteTextOutline,
    mdiParking,
    mdiChatQuestion,
    mdiBus,
    mdiBusStopUncovered,
    mdiCalendarClock,
    mdiLink,
    mdiMapMarkerDistance,
    mdiHuman,
    mdiFormatListBulletedSquare,
    mdiAllergy,
    mdiPill,
    mdiHospitalBox,
} from '@mdi/js';
import store from '@/store';

let menuItems = [
    {
        title: 'Accessibility',
        icon: mdiHuman,
        children: [
            {
                title: 'Entities',
                icon: mdiHomeOutline,
                to: 'EntityList',
            },
            {
                title: 'Categories',
                icon: mdiFormatListBulletedSquare,
                to: 'EntityCategoryList',
            },
            {
                title: 'Facilities',
                icon: mdiHumanMaleFemale,
                to: 'FacilityList',
            },
            {
                title: 'Medications',
                icon: mdiPill,
                to: 'MedicationList',
            },
            {
                title: 'Allergies',
                icon: mdiAllergy,
                to: 'AllergyList',
            },
            {
                title: 'Limitations',
                icon: mdiHospitalBox,
                to: 'LimitationList',
            },
            {
                title: 'Faqs',
                icon: mdiChatQuestion,
                to: 'FaqList',
            },
            {
                title: 'Links',
                icon: mdiLink,
                to: 'LinkList',
            },
        ],
    },
    {
        title: 'Mobility',
        icon: mdiMapMarkerDistance,
        children: [
            {
                title: 'Parks',
                icon: mdiParking,
                to: 'ParkList',
            },
            {
                title: 'Routes',
                icon: mdiBusStopUncovered,
                to: 'RoutesList',
            },
        ],
    },
    {
        title: 'Users',
        icon: mdiAccountOutline,
        to: 'UserList',
    },
];

/* let userRole = null;
if (store.getters['auth/getUser']) {
    userRole = store.getters['auth/getUser'].role.type;
    console.log('userRole', userRole);
} else {
    userRole = null;
}

if (userRole === 'ioparking') {
    menuItems = menuItems.filter(
        (item) => item.to !== 'TransportsList' && item.to !== 'RoutesList' && item.to !== 'SchedulesList',
    );
} else if (userRole === 'iobus') {
    menuItems = menuItems.filter((item) => item.to !== 'ParkList');
} else {
    menuItems = menuItems;
}
 */
export default menuItems;
